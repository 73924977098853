module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://fehrgroup.monsitetranquille.fr/graphql","html":{"useGatsbyImage":true,"imageQuality":100,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"placeholderType":"dominantColor"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FEHR Group : SPÉCIALISTE DU BÉTON PRÉFABRIQUÉ, FIBRÉ ET PRÊT À L’EMPLOI","short_name":"FEHR Group","icon":"./src/img/fehr-squared.png","start_url":"/","background_color":"#fff","theme_color":"#39b549","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5718422c06ffcdbb23de20884d38005"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-80,"duration":100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
