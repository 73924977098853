import React from "react"
import { Helmet } from "react-helmet"

import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const { location } = props
  return (
    <>
      <Helmet>
        <meta
          property="og:url"
          content={`https://fehrgroup.com${location.pathname}`}
        />
      </Helmet>
      {element}
    </>
  )
}
